html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f5fa !important;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Custome Font */

.font-8 {
  font-size: 8px !important;
}

.font-9 {
  font-size: 9px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-16-rem {
  font-size: 1.6rem;
}

.font-18 {
  font-size: 18px;
}

.font-18-rem {
  font-size: 1.8rem;
}

.font-20 {
  font-size: 20px;
}

.font-20-rem {
  font-size: 2rem;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-28 {
  font-size: 28px;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px;
}

.font-w-200 {
  font-weight: 200;
}

.font-w-300 {
  font-weight: 300;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

/* Custome Class */
.btn-outline-primary:hover {
  color: white !important;
  background-color: #0099cc;
  border-color: #0099cc;
}

button.dropdown-toggle.btn.btn-Warning {
  color: white !important;
}

button.dropdown-toggle.btn.btn-Warning.shadow-none {
  padding: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #0099cc !important;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0099cc !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057 !important;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

table.dataTable tbody td {
  vertical-align: middle;
}

table.dataTable td {
  padding: 8px 10px;
  width: 1px;
  white-space: nowrap;
  font-size: 11px !important;
}

table.dataTable th {
  padding: 8px 10px;
  width: 1px;
  white-space: nowrap;
  font-size: 11px !important;
}

/* Modal */
.modal-spinner {
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem;
  outline: 0;
  background-color: transparent;
}

.modal-spinner .modal-content {
  background: transparent;
  border: none;
}

.modal-90w {
  min-width: 90% !important;
}

/* dateoicker */
.react-datepicker__current-month {
  font-size: 12px !important;
}

.react-datepicker__day-names {
  font-size: 10px !important;
}

.react-datepicker__week {
  font-size: 10px !important;
}
